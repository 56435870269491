import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Alert, Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import moment from 'moment'
import { message } from 'antd'

import { storeDownloadToken } from '../../DownloadTokens/services'
import { getUri } from '../../../services/springApi'
import { handleError } from '../../../helpers'

export default function ExportVisitsModal({ visible, onClose }) {
	const user = useSelector(state => state.auth.details)
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			year: moment().format('YYYY').toString(),
			month: moment().format('MM').toString(),
		}
	})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)

		const token = Date.now() + Math.floor(1000 + Math.random() * 9000)

		const exportLink = `${getUri()}/exports/visits/${values.year}/${values.month}/${token}`

		storeDownloadToken({ user_id: user.id, type: 'Exportar Visitas', token })
			.then(() => {
				window.open(exportLink)
				message.success('Visitas exportadas', 10)
			})
			.catch(handleError)
			.finally(() => setLoading(false))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Exportar Visitas</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Año <span className='text-primary'>*</span></Form.Label>
							<Form.Control
								as="select"
								name="year"
								ref={register({ required:true })}
							>
								<option value="">:: Seleccione una opción ::</option>
								<option>2025</option>
								<option>2024</option>
								<option>2023</option>
								<option>2022</option>
								<option>2021</option>
							</Form.Control>
							{ errors.year && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Mes <span className='text-primary'>*</span></Form.Label>
							<Form.Control
								as="select"
								name="month"
								ref={register({ required:true })}
							>
								<option value="">:: Seleccione una opción ::</option>
								<option value="01">Enero</option>
								<option value="02">Febrero</option>
								<option value="03">Marzo</option>
								<option value="04">Abril</option>
								<option value="05">Mayo</option>
								<option value="06">Junio</option>
								<option value="07">Julio</option>
								<option value="08">Agosto</option>
								<option value="09">Septiembre</option>
								<option value="10">Octubre</option>
								<option value="11">Noviembre</option>
								<option value="12">Diciembre</option>
							</Form.Control>
							{ errors.month && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
					</Row>
					<Alert color='warning' className='p-2'>Tenga en cuenta que solo se exportara las visitas para las tiendas que tenga asociadas a su usuario.</Alert>
					<Button color="primary" type="submit" disabled={loading}>
						Exportar Visitas
						{" "}{ loading && <Spinner animation='border' size="sm" /> }
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}